<template>
  <div class="ds-container">
    <div class="ds-header">
      <div class="ds-title">
        Validade Padrão dos Orçamentos
      </div>
    </div>

    <div class="card">
      <div class="card-subtitle">
        Configure a Validade para cada tipo de Solicitação para automatizar o Processo de Validade nos Orçamentos
        <span id="help-icon" class="icon-box"><HelpCircle class="icon stroke" /></span>

        <b-tooltip target="help-icon" placement="bottom">
          Configure a Validade para cada tipo de Solicitação para automatizar o Processo de Validade nos Orçamentos
        </b-tooltip>
      </div>

      <div class="card-body px-0">

        <div class="row">
          <div class="col">
            <b-table-simple bordered class="p-table">
              <thead>
              <tr>
                <td class="td-header">Tipo de Solicitação</td>
                <td class="td-header">Validade</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(request,i) in this.requests" :key="i">
                <td class="td-body td-simple">
                  <p class="mt-2">{{ request.label }}</p>
                </td>
                <td class="td-body td-simple">
                  <b-row>
                    <b-col class="d-flex align-items-center justify-content-end">
                      <b-form-input
                          class="input-days"
                          type="number"
                          v-model="requests[i].value"
                          autocomplete="off"
                          placeholder="Dias..."
                      />
                      <div class="ml-2">dias</div>
                    </b-col>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col class="col-4">
        <p class="font-weight-bold mb-2">Validade de Orçamento</p>
        <multiselect
            class="expire-time form-control"
            id="expire_time_to_consider"
            v-model="expire_time_to_consider"
            :showLabels="false"
            :showNoResults="false"
            :searchable="false"
            :allow-empty="false"
            track-by="value"
            label="label"
            placeholder="Selecionar..."
            :options="Array.from(expireTimeToConsider.values())"
        >
          <template #caret>
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template #singleLabel="props">
            <div>
              {{props.option.label}}
            </div>
          </template>
        </multiselect>
      </b-col>
      <b-col class="d-flex align-items-end justify-content-end">
        <b-button
          variant="primary"
          size="lg"
          @click="save"
        >Salvar</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
import {requestTypes, expireTimeToConsider} from "@/modules/SellingPanel/config/budget"
import {mapState, mapActions} from 'vuex'

export default {
  props: {
    title: {type: String}
  },
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
  },
  data() {
    return {
      canEditModule: userHasPermission('SPCfg'),
      clinic: getCurrentClinic(),
      requests: [],
      expire_time_to_consider: null, // default value
      requestTypes,
      expireTimeToConsider,
    }
  },
  computed: {
    ...mapState({
      configs: state => state.sellingPanel.configs
    })
  },
  mounted() {

    if(! this.configs) {
      this.getConfigs(this.clinic.id).then( () => {
        this.setConfigs()
      })
    }
    else {
      this.setConfigs()
    }
  },

  methods: {
    ...mapActions('sellingPanel', ['getConfigs', 'saveConfigs']),

    setConfigs() {
      this.expire_time_to_consider = expireTimeToConsider.get(this.configs.EXPIRE_TIME_TO_CONSIDER)
      this.requestTypes.forEach((req,key) => {
        this.requests.push({
          label: req.label,
          key,
          value: this.configs.EXPIRE_TIME_IN_DAYS[key]
        })
      })
    },
    save() {
      const data = { EXPIRE_TIME_TO_CONSIDER: null, EXPIRE_TIME_IN_DAYS: {} }
      this.requests.forEach( req => {
        data.EXPIRE_TIME_IN_DAYS[req.key] = req.value
      })
      data.EXPIRE_TIME_TO_CONSIDER = this.expire_time_to_consider.value

      const l = this.$loading.show()
      this.saveConfigs({clinic: this.clinic.id, data}).then(() => {
        this.$toast.success('As Configurações foram Modificadas')
      })
      .catch(error => {
        this.$toast.error('Por favor, revise se todas as datas de Expiração foram configuradas corretamente. Se o erro persistir, contate nosso Suporte.')
        console.error(error)
      })
      .finally(() => {
        l.hide()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.ds-container {
    padding: 30px 10px;
    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .ds-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }
    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
    .small-icon {
      width: 20px;
      margin-left: 5px;
    }
}
.card {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  margin: 20px 0;
}
.card-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--dark-blue);
}
.option {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.togle-button {
  margin-right: 15px;
}

.p-table {
  width: 100%;
  text-align: left;
  margin: 20px 0;
  border-radius: 3px !important;

  .td-header {
    font-weight: 700;
    font-size: 14px;
    color: var(--type-active);
    background-color: var(--neutral-100);
    border: none;
    padding: 20px 10px;
  }
  .td-body {
    padding: 20px 10px;
    border-top: 1px dashed var(--neutral-300);
    font-weight: 600;
    font-size: 16px;
    color: var(--type-active);
  }
  .td-simple {
    font-weight: normal !important;
  }
  td, th:nth-child(1){
    width: 90%;
  }
  td, th:nth-child(2){
    width: 10%;
  }
  .procedure-text {
    max-width: 150px;
    margin-left: 10px;
  }
  .number {
    font-family: 'Red Hat Display';
    font-weight: 700;
    color: var(--neutral-500);
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  .blue-icon {
    fill: #0C1D59;
  }
  .use-type {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
  }
  .product-name {
    cursor: pointer;
  }
  .input-days{
    width: 75px;
  }
}
.btn {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  padding: 10px 20px;
  border-radius: 6px !important;
  line-height: 150%;
}
</style>
