/**
 * Budget List Item Request types
 * @todo discuss if this information could be extracted from the item list
 * @type {Map<string, {label: string}>}
 */
const requestTypes = new Map([
    ['EXAM', { label: 'Exame' }],
    ['PROCEDURE', { label: 'Procedimento' }],
    ['SURGERY', { label: 'Cirurgia' }],
    ['PRODUCT', { label: 'Produto' }],
    ['TREATMENT', { label: 'Tratamento' }],
])

const expireTimeToConsider = new Map([
    ['GREATER', {label: 'Considerar A MAIOR data de expiração', value: 'GREATER'}],
    ['LOWER', {label: 'Considerar A MENOR data de expiração', value: 'LOWER'}]
])

const budgetStatus = {
    IN_NEGOTIATION: 'IN_NEGOTIATION',
    DENIED: 'DENIED',
    RENEGOTIATION: 'RENEGOTIATION',
    CANCELED: 'CANCELED',
    FINISHED_SUCCEEDED: 'FINISHED_SUCCEEDED',
    AUTHORIZED: 'AUTHORIZED',
    WAITING_AUTHORIZATION: 'WAITING_AUTHORIZED',
}

/**
 * Just an item addition to manage Private (particular) status
 * @type {Map<string, {style: {"background-color": string, color: string}, label: string}>}
 */
const healthPlanStatus = new Map([
    [budgetStatus.WAITING_AUTHORIZATION,{ label: 'Aguardando Autorização', style: {'background-color': '#E6F5FF', 'color': '#525C7A'}}],
    [budgetStatus.IN_NEGOTIATION, { label: 'Negociação', style: {'background-color': '#FFEBCC', 'color': '#525C7A'}}],
    [budgetStatus.AUTHORIZED, { label: 'Autorizado', style: {'background-color': '#E7FFDB', 'color': '#525C7A'}}],
    [budgetStatus.DENIED, { label: 'Negado', style: {'background-color': '#FED2CE', 'color': '#525C7A'}}],
    [budgetStatus.RENEGOTIATION, { label: 'Renegociação', style: {'background-color': '#E2CCFF', 'color': '#525C7A'} }],
    [budgetStatus.CANCELED, { label: 'Cancelado', style: {'background-color': '#E9EBF2', 'color': '#525C7A'}}],
    [budgetStatus.FINISHED_SUCCEEDED, { label: 'Finalizado', style: {'background-color': '#E7ECFE', 'color': '#525C7A'}}],
])
/**
 * For health plan (convênios) status management
 * @type {Map<string, {style: {"background-color": string, color: string}, label: string}>}
 */

const privateStatus = new Map(healthPlanStatus.entries())
privateStatus.delete(budgetStatus.WAITING_AUTHORIZATION)

const defaultFormData = {
    id: null,
    patient_id: null,
    clinic_id: null,
    health_plan_id: null, // if the patient has a health insurance plan
    requester_doctor_id: null, // the doctor that requested the budget items
    responsible_clinic_person_id: null, // the clinic person responsible by the budget proposal
    expires_at: null, // when the budget proposal will be considered invalid
    request_types: [], // see budget.js
    status: budgetStatus.IN_NEGOTIATION, // see budget.js
    description: null, // brief description,
    notes: [], // budget annotations and reminders
    items: [],
    files: [],
}
// methods
/**
 * Verify if the budge status is included at the allowed changing statuses
 * @param {String} status
 * @returns {boolean}
 */
const canModifyByStatus = (status) => {
    return [
        budgetStatus.IN_NEGOTIATION,
        budgetStatus.RENEGOTIATION,
        budgetStatus.WAITING_AUTHORIZATION,
        budgetStatus.DENIED
    ]
        .includes(status)
}
// at last exports
export {
    requestTypes,
    expireTimeToConsider,
    healthPlanStatus,
    privateStatus,
    defaultFormData,
    budgetStatus,
    // methods
    canModifyByStatus
}
